<template>
  <el-dialog class="include-gis-dialog" :visible.sync="dialogForms" :close-on-press-escape="false"
    :close-on-click-modal="false" top="10vh" width="1185px" height="721px" title="站点信息" :destroy-on-close="true"
    @close="close()">
    <template slot="title">
      <div class="dialogTitles"><span></span> <b>监测点位列表</b></div>
    </template>
    <div class="tabs">
      <div class="topSearch">
        监测点位 <el-input class="selectBox" size="small" v-model="pageParams.pointName" placeholder="请输入"></el-input>
        数据类型 <el-select class="selectBox" size="small" v-model="pageParams.dataType" placeholder="请选择">
          <el-option v-for="item in dataType" :key="item.type" :label="item.name" :value="item.type"></el-option>
        </el-select>
        <div class="searchBtn">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button type="info" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <el-table :data="tableData" border class="tableStyle" height="calc(100vh - 410px)">
        <el-table-column label="序号" type="index" width="52"></el-table-column>
        <el-table-column v-for="col in head" :key="col.prop" :prop="col.prop" :label="col.label" align="left"
          show-overflow-tooltip :sortable="col.sorts" :width="col.widths">
          <template v-if="col.isBar" #default="{ row }">
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70">
          <template slot-scope="{ row }">
            <el-checkbox v-model="row.checked">&nbsp;</el-checkbox>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="dialog-footer">
      <!-- 按钮顺序 重置 取消 保存 -->
      <el-button size="medium" @click="close()">取消</el-button>
      <el-button type="primary" size="medium" @click="save">添加</el-button>
    </div>
  </el-dialog>
</template>

<script>
import equipmentManageApi from '@/apis/equipmentManage/equipmentManageApi'
import monitoringApi from '@/apis/siteAdministration/monitoringApi'
import indexMixin from '@/mixins/index.js'
import { mapActions, mapState } from 'vuex'
export default {
  mixins: [indexMixin],
  data() {
    return {
      pageParams: {
        size: -1,
        current: 1
      },
      tableData: [],
      head: [
        { prop: 'pointName', label: '点位名称' },
        { prop: 'pointCode', label: '点位编号' },
        { prop: 'dataTypeName', label: '数据类型' },
        { prop: 'importantName', label: '重点检测' },
        { prop: 'supplyCalculateName', label: '参与供气量计算' },
        { prop: 'description', label: '监测位置描述' },
        { prop: 'collectPointName', label: '远传采集点' }
      ],
      total: 0,
      equipmentId: null,
      stationId: null,
      typeName: '',
      equipmentName: '',
      dialogForms: false,
      info: '弹框信息'

    }
  },
  computed: {
    ...mapState('dict', ['dataType'])
  },
  created() {
    console.log('created');
  },
  mounted() {},
  methods: {
    ...mapActions('dict', ['getDropdown', 'getDataType']),
    async open(equipmentId, stationId, equipmentName, typeName) {
      await this.getDropdown('important')
      await this.getDataType()
      this.typeName = typeName
      this.equipmentName = equipmentName
      this.equipmentId = equipmentId
      this.stationId = stationId
      this.$nextTick(() => {
        this.getList(this.pageParams)
        this.dialogForms = true
      })
    },
    /**
     * Description  获取列表
     * @author slx
     * @date 2024-01-09
     * @param {any} pageParams 请求参数对象
     * @returns {any}
     */
    async getList(pageParams) {
      let params = JSON.parse(JSON.stringify(pageParams))
      let {stationId} = this
      if(!stationId) {
        this.tableData = []
        this.total = 0
        return
      }
      params.eqBindStatus = 2
      params.dataFlag = 'scada'
      params.scadaStationId = stationId
      monitoringApi.getList(params).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.list
          this.total = res.data.total
          this.setSelect()
        } else this.$message.error(res.msg)
      })
    },
    /**
     * Description  重新获取列表的时候清空已选数据
     * @author slx
     * @date 2024-01-09
     * @returns {any}
     */
    setSelect() {
      this.tableData.forEach(e => { this.$set(e, 'checked', false) })
    },
    /**
     * Description  添加按钮
     * @author slx
     * @date 2024-01-09
     * @returns {any}
     */
    save() {
      let {equipmentId, equipmentName} = this
      let selectArr = this.tableData.filter(item => { return item.checked == true })
        if (!selectArr.length > 0) return this.$message.error('请选择监测点位')
        let monitorPointIds = selectArr.map(item => {return item.id}).toString()
        let msg = `确认将所选择的监测点位与【${equipmentName}】进行绑定？`
        this.bindPoint(msg, {monitorPointIds, equipmentId})
    },
    /**
     * Description  绑定
     * @author slx
     * @date 2024-01-09
     * @param {any} msg 提示信息
     * @param {any} params 请求参数
     * @returns {any}
     */
    bindPoint(msg, params) {
      this.$confirm(msg, '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          equipmentManageApi.monitorPoint(params).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.$parent.$refs.monitoring.reset()
              this.close()
            } else this.$message.error(res.msg)
          })
        }).catch(action => {});
    },
    search() {
      this.getList(this.pageParams)
    },
    reset() {
      this.pageParams = {
        current: 1,
        size: -1
      }
      this.getList(this.pageParams)
    },
    close() {
      Object.assign(this.$data, this.$options.data())
    },
  }
}
</script>

<style lang="scss" scoped>
.linkBtn {
  cursor: pointer;
  color: #1082FF;
}

.tabs {
  background: #fff;
  padding: 16px 20px 8px 20px;
  height: 594px;

  .topSearch {
    color: #0F0F0F;
    border-bottom: 1px solid #E3E3E3;
    padding: 0 10px 16px;

    .selectBox {
      width: 200px;
      margin: 0 30px 0 4px;
    }

    .searchBtn {
      display: inline-block;
    }
  }

  .operateBtn {
    margin: 16px 0 10px;
  }
}
</style>