//  引入拦截器
import axios from '@/common/js/request';

export default {
  //  获取列表
  getList(params) {
      return axios({
          url: '/equipment/page',
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
          params
      })
  },
  //  新增
  addEquipment(data) {
      return axios({
          url: '/equipment/save',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          data
      })
  },
  //  删除
  deleteEquipment(params) {
      return axios({
          url: '/equipment/delete',
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
          params
      })
  },
  //  导出
  exportEquipment(params) {
      return axios({
          url: '/equipment/export',
          method: 'GET',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          responseType: 'blob', //改变接收的值类型
          params
      })
  },
  //  查询单条
  getEquipmentInfo(params) {
      return axios({
          url: '/equipment/findById',
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
          params
      })
  },
  //  绑定-解绑监测点
  monitorPoint(data) {
      return axios({
          url: '/equipment/monitorPoint',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          data
      })
  },
  //  启用禁用
  togglePointStatus(data) {
      return axios({
          url: '/equipment/status',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          data
      })
  },
  //  修改
  updateEquipment(data) {
      return axios({
          url: '/equipment/update',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          data
      })
  }
}